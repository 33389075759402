import React from "react";
import categories_react_select from "shared/categories/categories_transformer";
import Select from "react-select";

export default function EditQuestionItem({
  question,
  isPostCreator,
  updatePost,
  setEditableTitle,
  editableTitle,
  setEditableContent,
  editableContent,
  editableTag,
  setEditableTag,
  editableIsAnonymous,
  setEditableIsAnonymous,
}) {
  return (
    <form
      className="w-[95%] mx-auto p-3 border border-indigo-600 border-solid rounded-md "
      onSubmit={updatePost}
    >
      <label className="block m-2 text-md font-semibold underline text-gray-700 mb-1">
        Title
      </label>
      <input
        onChange={(event) => setEditableTitle(event.target.value)}
        className="whitespace-pre-wrap w-full py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={editableTitle}
      />
      <label className="block m-2 text-md font-semibold underline text-gray-700 mb-1">
        Content
      </label>
      <textarea
        onChange={(event) => setEditableContent(event.target.value)}
        className="whitespace-pre-wrap w-full py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={editableContent}
      />
      {/* Allowing User to edit the tags IF they are the one who posted the question */}
      {question && isPostCreator ? (
        <Select
          isMulti
          value={editableTag}
          onChange={(tags) => setEditableTag(tags)}
          options={categories_react_select}
          placeholder="Select tags..."
          className="react-select-container"
          classNamePrefix="react-select"
          required
        />
      ) : null}

      <div className="flex flex-row gap-2    mt-2">
        <input
          data-testid="checkBoxEdit"
          checked={editableIsAnonymous}
          onChange={() => setEditableIsAnonymous(!editableIsAnonymous)}
          type="checkbox"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
        />
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Would you like this post to be anonymous?
        </label>
      </div>
      <div className="flex justify-end">
        <button
          data-testid="submit-button"
          type="submit"
          className="m-2 py-1 px-3 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}
