import { useEffect, useContext, useState, useCallback } from "react";
import TopAnswer from "./TopAnswer.js";
import UserDetailsComponent from "../Users/UserDetailsComponent.js";
import { AuthContext } from "../Auth/AuthProvider.js";
import { Timestamp, setDoc, doc } from "firebase/firestore";
import { db } from "../Auth/firebase.js";
import PostBottom from "./PostBottom.js";
import categories_react_select from "shared/categories/categories_transformer";
import Select from "react-select";
import EditQuestionItem from "./EditQuestionItem.js";
import { formatDistanceToNow } from "date-fns";

const QuestionItem = ({
  post,
  postCreator,
  answerers,
  onDelete,
  reply_page = false,
}) => {
  const content = post?.content;
  const title = post?.title;
  const question = post?.type == 1;
  const tags = post?.tags;
  const timestamp = post?.timestamp;
  const isAnonymous = post?.isAnonymous;

  const { user } = useContext(AuthContext);

  // const [formattedTimestamp, setFormattedTimestamp] = useState("");
  const [relativeTimestamp, setRelativeTimestamp] = useState("");
  const [timestampError, setTimestampError] = useState(false);

  // useEffect(() => {
  //   try {

  //     if (timestamp) {
  //       if (typeof timestamp.seconds === "number") {
  //         setFormattedTimestamp(new Date(timestamp.seconds * 1000).toLocaleString());
  //         setTimestampError(false);
  //       } else if (typeof timestamp._seconds === "number") {
  //         setFormattedTimestamp(new Date(timestamp._seconds * 1000).toLocaleString());
  //         setTimestampError(false);
  //       } else {
  //         throw new Error("Timestamp structure is incorrect.");
  //       }
  //     } else {
  //       throw new Error("Timestamp is undefined or null.");
  //     }
  //   } catch (error) {
  //     console.error("Error formatting timestamp:", error);
  //     setTimestampError(true);
  //   }
  // }, [timestamp]);

  useEffect(() => {
    try {
      if (timestamp) {
        let date;
        if (typeof timestamp.seconds === "number") {
          date = new Date(timestamp.seconds * 1000); // Convert Firestore timestamp to Date
        } else if (typeof timestamp._seconds === "number") {
          date = new Date(timestamp._seconds * 1000); // Handle cases with _seconds
        } else {
          throw new Error("Timestamp structure is incorrect.");
        }

        setRelativeTimestamp(formatDistanceToNow(date, { addSuffix: true }));
        setTimestampError(false);
      } else {
        throw new Error("Timestamp is undefined or null.");
      }
    } catch (error) {
      console.error("Error formatting timestamp:", error);
      setTimestampError(true);
    }
  }, [timestamp]);

  const [isPostCreator, setIsPostCreator] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const changeEditStatus = () => {
    setIsEdit(!isEdit);
  };

  const [editableTitle, setEditableTitle] = useState(title);
  const [editableContent, setEditableContent] = useState(content);
  const [editableTag, setEditableTag] = useState([]);
  const [editableIsAnonymous, setEditableIsAnonymous] = useState(false);

  useEffect(() => {
    if (post) {
      setEditableTitle(post?.title);
      setEditableContent(post?.content);
      setEditableIsAnonymous(post?.isAnonymous);
      const formattedTags = post?.tags.map((tag) => ({
        label: tag,
        value: tag,
      }));
      setEditableTag(formattedTags);
    }
  }, [post]);

  const updatePost = useCallback(
    async (event) => {
      try {
        event.preventDefault();

        // creating a new updated Doc
        const updatedDoc = {
          type: 1,
          title: editableTitle,
          content: editableContent,
          uid: user.uid,
          timestamp: Timestamp.now(),
          tags:
            question && isPostCreator
              ? editableTag.map((element) => element.value)
              : post?.tags,
          isAnonymous: editableIsAnonymous,
        };

        // Finding the DB entry where I will be updating
        const postRef = doc(db, "posts", post?.id);

        // Updating Doc
        await setDoc(postRef, updatedDoc, { merge: true });
        post.title = editableTitle;
        post.content = editableContent;
        post.tags = isPostCreator ? editableTag : post?.tags;

        post.isAnonymous = editableIsAnonymous;
        setIsEdit(false);
      } catch (error) {
        console.error("Error updating post:", error);
      }
    },
    [
      editableContent,
      editableTitle,
      editableTag,
      question,
      isPostCreator,
      editableIsAnonymous,
    ]
  );

  useEffect(() => {
    if (user && post) {
      setIsPostCreator(postCreator?.uid === user?.uid);
    }
  }, [user, post]);

  return (
    <div className="flex flex-col border rounded-lg shadow-lg bg-white relative border-zinc-300	drop-shadow">
      <UserDetailsComponent
        postCreator={postCreator}
        isAnonymous={isAnonymous}
      />

      {/* Timestamp
      {!timestampError && (
        <div className="text-xs text-gray-500 px-1">
          Posted on: {formattedTimestamp}
        </div>
      )} */}

      {/* Relative Timestamp */}
      {!timestampError && (
        <div className="text-xs text-gray-500 px-1">
          Posted {relativeTimestamp}
        </div>
      )}

      {/* Content */}
      <div className="flex flex-col relative">
        {!isEdit ? (
          <>
            <div
              className="font-sans text-xl pb-1 font-bold px-1"
              data-testid="post-title"
            >
              {title}
            </div>
            <div
              className="whitespace-pre-wrap px-1 break-all"
              data-testid="post-content"
            >
              {content}
            </div>
          </>
        ) : (
          <EditQuestionItem
            question={question}
            isPostCreator={isPostCreator}
            updatePost={updatePost}
            setEditableTitle={setEditableTitle}
            editableTitle={editableTitle}
            setEditableContent={setEditableContent}
            editableContent={editableContent}
            editableTag={editableTag}
            setEditableTag={setEditableTag}
            editableIsAnonymous={editableIsAnonymous}
            setEditableIsAnonymous={setEditableIsAnonymous}
          />
        )}
      </div>
      <PostBottom
        changeEditStatus={changeEditStatus}
        isEdit={isEdit}
        post={post}
        isPostCreator={isPostCreator}
        answerers={answerers}
        commentIconBool={true}
        onDelete={onDelete}
        reply_page={reply_page}
      />
      {/* Reply */}
      {!reply_page && post?.replies && post.replies.length > 0 && (
        <TopAnswer answer_id={post.replies[0]} question_id={post?.id} />
      )}
    </div>
  );
};

export default QuestionItem;
