import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Leftsidebar from "../Sidebar/Sidebar.js";
import InfiniteScroll from "react-infinite-scroll-component";
import QuestionItem from "./QuestionItem.js";
import AnswerItem from "./AnswerItem.js";
import { db } from "../Auth/firebase.js";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { backend_url } from "../../config.js";
import { AuthContext } from "../Auth/AuthProvider.js";
import ReplyModal from "./ReplyModal.jsx";

const Answers = () => {
  const { user, userInfo, accessToken } = useContext(AuthContext);

  const [reply, setReply] = useState("");
  const [answersAndCreators, setAnswersAndCreators] = useState([]);
  const [postCreator, setPostCreator] = useState(null);
  const [post, setPost] = useState(null);
  const { postId } = useParams();
  const [hasMorePosts, setHasMorePosts] = useState(false);
  const [selectedReply, setSelectedReply] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleReplyClick = (item) => {
    setSelectedReply(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedReply(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetch(backend_url + "/api/answers_page_from_id?post_id=" + postId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPostCreator(data.postCreator);
        setPost(data.question);
        setAnswersAndCreators(data.answers);
        return data;
      })
      .catch((error) => {
        console.error("Error retrieving post or children", error);
      });
  }, [postId]);

  const handleSubmitReply = async (e) => {
    e.preventDefault();
    try {
      const newDoc = {
        type: 2, // 2 is an answer
        content: reply,
        uid: user.uid,
        parent: postId,
        timestamp: Timestamp.now(),
      };
      const docRef = await addDoc(collection(db, "posts"), newDoc);
      newDoc.id = docRef.id;
      const new_doc_and_creator = {
        reply: newDoc,
        creator: userInfo,
      };

      fetch(
        backend_url +
          "/update/post/add_reply?post_id=" +
          postId +
          "&reply_id=" +
          docRef.id,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer: " + accessToken,
          },
        }
      );

      setAnswersAndCreators((answersAndCreators) => [
        new_doc_and_creator,
        ...answersAndCreators,
      ]);

      setReply("");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  const handleDeletePost = (postId) => {
    setAnswersAndCreators((prevItems) =>
      prevItems.filter((item) => item.reply.id !== postId)
    );
  };

  return (
    <div className="flex flex-wrap w-full h-full">
      <div className="flex flex-row w-full bg-gray-100">
        <Leftsidebar />

        <main className="flex flex-col grow p-6">
          <QuestionItem
            key={postId}
            post={post}
            postCreator={postCreator}
            reply_page={true}
          />

          {user ? (
            <form onSubmit={handleSubmitReply} className="mb-6">
              <textarea
                rows={3}
                value={reply}
                onChange={(e) => setReply(e.target.value)}
                type="text"
                name="reply"
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 mt-4"
                placeholder="Answer question here..."
              />
              <button
                type="submit"
                className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-700 transition duration-200"
              >
                SEND
              </button>
            </form>
          ) : (
            <div className="text-center text-gray-500">
              <p className="text-center text-gray-500">
                Please log in to participate.
              </p>
            </div>
          )}

          <InfiniteScroll
            dataLength={answersAndCreators.length}
            hasMore={hasMorePosts}
            loader={
              <h4 className="text-center text-gray-500 animate-pulse">
                Loading...
              </h4>
            }
            endMessage={
              <p className="text-center text-gray-600 mt-4">
                <b>That's all!</b>
              </p>
            }
            className="space-y-4"
          >
            <div className="space-y-4">
              {answersAndCreators?.map((item) => (
                <AnswerItem
                  key={item.reply.id}
                  item={item}
                  post={item.reply}
                  postCreator={item.creator}
                  onDelete={handleDeletePost}
                  handleReplyClick={handleReplyClick}
                />
              ))}
            </div>
          </InfiniteScroll>
          <ReplyModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            reply={selectedReply ? selectedReply.reply : null}
            creator={selectedReply ? selectedReply.creator : null}
            user={user}
            accessToken={accessToken}
            backend_url={backend_url}
          />
        </main>
      </div>
    </div>
  );
};

export default Answers;
