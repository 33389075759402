import React, { useContext, useState } from "react";
import { Sidebar, Menu, MenuItem, menuClasses } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import {
  HourglassBottom,
  Diamond,
  Category,
  ContactSupport,
  DarkMode,
  LightMode,
} from "@mui/icons-material";

import { useCategory } from "../../utils/CategoryContext";
import { AuthContext } from "../Auth/AuthProvider";
import categories_react_select from "shared/categories/categories_transformer";
import { Typography } from "@mui/material";

// Theme configuration
const themes = {
  light: {
    sidebar: {
      background: "#F3F4F6",
      text: "#607489",
      accent: "#0098e5",
    },
    menu: {
      hover: "#c5e4ff",
      active: "#e6f2ff",
    },
  },
  dark: {
    sidebar: {
      background: "#0b2948",
      text: "#8ba1b7",
      accent: "#59d0ff",
    },
    menu: {
      hover: "#00458b",
      active: "#13395e",
    },
  },
};

export default function Leftsidebar() {
  const navigate = useNavigate();
  const { userInfo } = useContext(AuthContext);
  const { category, setCategory } = useCategory();

  const [theme, setTheme] = useState("light");
  const [collapsed, setCollapsed] = useState(false);

  const currentTheme = themes[theme];

  const menuItemStyles = {
    root: {
      fontSize: "14px",
      fontWeight: 500,
      borderRadius: "8px",
      margin: "4px 8px",
    },
    icon: {
      color: currentTheme.sidebar.accent,
    },
    button: {
      "&:hover": {
        backgroundColor: currentTheme.menu.hover,
        color: currentTheme.sidebar.text,
      },
      "&.active": {
        backgroundColor: currentTheme.menu.active,
        color: currentTheme.sidebar.accent,
      },
    },
  };

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const renderSidebarSection = (title, items) => (
    <>
      <div className="px-4 my-4">
        <Typography
          variant="body2"
          fontWeight={600}
          style={{
            opacity: collapsed ? 0 : 0.7,
            letterSpacing: "0.5px",
          }}
        >
          {title}
        </Typography>
      </div>
      <Menu menuItemStyles={menuItemStyles}>
        {items.map((item, index) => (
          <MenuItem
            key={index}
            icon={item.icon}
            active={category === item.label}
            onClick={() => {
              setCategory(item.label);
              navigate(item.route || "/");
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  return (
    <Sidebar
      collapsed={collapsed}
      backgroundColor={currentTheme.sidebar.background}
      rootStyles={{
        color: currentTheme.sidebar.text,
        height: "100%",
      }}
    >
      <div className="h-full flex flex-col">
        {/* Theme Toggle */}
        <div className="flex items-center justify-between p-4">
          <button
            onClick={() => setCollapsed(!collapsed)}
            className="hover:bg-gray-200 p-2 rounded-full"
          >
            {collapsed ? "→" : "←"}
          </button>
          <button
            onClick={toggleTheme}
            className="hover:bg-gray-200 p-2 rounded-full"
          >
            {theme === "light" ? <DarkMode /> : <LightMode />}
          </button>
        </div>

        {/* Feed Display Section */}
        {renderSidebarSection("Feed Display", [
          {
            label: "New",
            icon: <HourglassBottom />,
            route: "/",
          },
          {
            label: "Top",
            icon: <Diamond />,
            route: "/",
          },
        ])}

        {/* Topics Section */}
        {renderSidebarSection("Topics", [
          {
            label: "All",
            icon: <Category />,
          },
          ...categories_react_select.map((category) => ({
            label: category.label,
            icon: <Category />,
          })),
        ])}

        {/* Support Section */}
        {renderSidebarSection("Support", [
          {
            label: "Contact Us",
            icon: <ContactSupport />,
            route: `/settings/${userInfo?.link}`,
          },
          {
            label: "Join Newsletter",
            icon: <ContactSupport />,
            route: `/settings/${userInfo?.link}`,
          },
        ])}
      </div>
    </Sidebar>
  );
}
